<body class="account-page">
  <div class="main-wrapper" style="margin-top: 110px;">
    <div class="account-content">
      <div class="container">
        <div class="account-logo">
          <a><img src="../../../assets/img/logo.png" alt="Dreamguy's Technologies"></a>
        </div>
        <div class="account-box">
          <div class="account-wrapper">
            <h3 class="account-title">Login</h3>
            <p class="account-subtitle">Access to our dashboard</p>
            <form action="index.html">
              <div class="form-group">
                <label>Email Address</label>
                <input class="form-control"  type="email" placeholder="Email..." name="email" required="" #email onpaste="return false;" ondrop="return false;" autocomplete="off" [(ngModel)]="emailValue" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Password</label>
                  </div>
                  <div class="col-auto">
                    <a class="text-muted" routerLink="/forget-password" >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <input class="form-control" type="password" placeholder="Password.."  name="password" required=""  #password [(ngModel)]="passwords" onpaste="return false;" ondrop="return false;" autocomplete="off">
              </div>
              <div class="form-group text-center">
                <button class="btn btn-primary account-btn" (click)="singInClicked(email.value, passwords)">Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <script src="../../../assets/js/jquery-3.2.1.min.js"></script>
  <script src="../../../assets/js/popper.min.js"></script>
  <script src="../../../assets/js/bootstrap.min.js"></script>
  <script src="../../../assets/js/app.js"></script>

</body>
