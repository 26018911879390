export class Url {

  // url = ''

  public static baseUrl: string = 'https://node.appsync.ch';

  public static apiUrl: string = `${Url.baseUrl}/api`;
  public static apiImagePath: string = `${Url.baseUrl}/images/`;
  public static apiImageUpload: string = `${Url.baseUrl}/upload`;
  public static apiImageUploadEditor: string = `${Url.baseUrl}/upload/editor`;
  public static apiFilePath: string = `${Url.baseUrl}/file/`;
}
