import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { currentUser } from '../models/currentUser.model';
import { Auth } from 'src/app/common/auth';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-login',
    templateUrl: '../views/login.component.html',
    styleUrls: ['../styles/login.component.css'],
})
export class LoginComponent implements OnInit {

  currentUserResponse = new currentUser();
  signInButton = true;
  passwords;
  passVisible=true;
  emailValue;
  changedPassword=false;
  attempt;
  diplaySignIn = false;
  userAuth: Auth = Auth.getDataOfUser();
  langFromDB;
  queryParams = false;

  private routeSub: Subscription;
  constructor(private router: Router,
      private route: ActivatedRoute,
      private auth: AuthService) {

  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      console.log(params.newAccount);
      if(params.newAccount) {
        this.queryParams = true;
      }
    });
  }

  singInClicked(email, password) {
    console.log(email, password);
    if(email != null && email != undefined && password != null && password != undefined){
      this.singIn(email, password);
    }
  }

  async singIn(email, password) {
    console.error('email: ' + email + ' password: ' + password);

    await this.auth.getUser({email: email, password: password}).subscribe(response => {
      console.log(response);
      this.currentUserResponse = response.User[0];
      this.attempt = response.Attempt[0].attemps;
      this.langFromDB = response.Lang[0].lang;

      localStorage.setItem('lang', this.langFromDB);

      if(response.User.length != 0) {
        localStorage.setItem('currentUser', JSON.stringify(response.User[0]));
          this.auth.postUserToken({ email: email, password: password }).subscribe(response => {
            console.log(response);
            this.currentUserResponse.token = response.token;
            localStorage.setItem('currentUser', JSON.stringify(this.currentUserResponse));
            this.router.navigate(['/dashboard']);
          });
          console.error(this.currentUserResponse);
      }
    });
  }

  singUp(name, email, password) {
    console.error('name: ' + name + ' email: ' + email + ' password: ' + password);

    this.auth.postUser({ name: name, email: email, password: password }).subscribe(response => {
      console.log(response);
      localStorage.setItem('currentUser', response.password);
      this.router.navigate(['/dashboard']);
    });
  }


  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.diplaySignIn = true;
  }

}
