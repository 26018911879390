
export class Auth {
  id = null;
  name = null;
  email = null;
  password = null;
  role = null;
  token = null;
  attemps = null;
  lang = null;
  birthday = null;
  phone = null;
  bank_name = null;
  bank_account_nr = null;
  address = null;
  profile_img_original_name = null;
  profile_img_modified_name = null;
  contract_file_original_name = null;
  contract_file_modified_name = null;
  slug = null;
  department_id = null;
  department_name = null;
  activity = null;



  get isLogged(): boolean {
    return this.email != null;
  }

  get isAdmin(): boolean {
    return this.role == 'admin';
  }

  get isUser(): boolean {
    return this.role == 'user';
  }




  static getDataOfUser(): Auth {
    const currentUser: Auth = JSON.parse(localStorage.getItem('currentUser'));

    const userAuth = new Auth();

    for (var key in currentUser) {
      if (currentUser.hasOwnProperty(key)) {
        userAuth[key] = currentUser[key];
      }
    }

    return userAuth;
  }

}



