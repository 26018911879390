<body class="error-page">
  <div class="main-wrapper">
    <div class="error-box">
      <h1>404</h1>
      <h3><i class="fa fa-warning"></i> Oops! Page not found!</h3>
      <p>The page you requested was not found.</p>
      <a (click)="goToHome()" class="btn btn-custom">Back to Home</a>
    </div>
  </div>

  <script src="assets/js/jquery-3.2.1.min.js"></script>
  <script src="assets/js/popper.min.js"></script>
  <script src="assets/js/bootstrap.min.js"></script>
  <script src="assets/js/app.js"></script>
</body>
