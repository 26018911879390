<div class="main-wrapper" [ngClass]="{'slide-nav' : openSideBar}">
  <div class="header">
    <div class="header-left">
      <a href="index.html" class="logo">
        <img src="../../../../assets/img/logo.png" height="40" alt="">
      </a>
    </div>

    <a id="toggle_btn" (click)="sidebarClicked()" style="cursor: pointer;">
      <span class="bar-icon">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </a>

    <div class="page-title-box">
      <h3>Trokit</h3>
    </div>

    <a class="mobile_btn"  (click)="sidebarClicked()" style="cursor: pointer;"><i class="fa fa-bars"></i></a>
      <ul class="nav user-menu">
        <li class="nav-item dropdown has-arrow main-drop" [ngClass]="{'show' : openAdminDropDown}" aria-expanded="false" (click)="adminClicked()">
          <a  class="dropdown-toggle nav-link" data-toggle="dropdown">
            <span class="user-img"><img [src]=getSantizeUrl(userAuth.profile_img_modified_name) alt="">
            <span class="status online"></span></span>
            <span>{{ userAuth.name }}</span>
          </a>
          <div class="dropdown-menu" [ngClass]="{'show' : openAdminDropDown}">
            <a class="dropdown-item">My Profile</a>
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>
      <!-- /Header Menu -->

      <!-- Mobile Menu -->
      <div class="dropdown mobile-user-menu ">
        <a  class="nav-link dropdown-toggle" [ngClass]="{'show' : openAdminDropDown}" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
        <div class="dropdown-menu dropdown-menu-right" [ngClass]="{'show' : openAdminDropDown}">
          <a class="dropdown-item">My Profile</a>
        </div>
      </div>
      <!-- /Mobile Menu -->

    </div>
    <!-- /Header -->

    <!-- Sidebar -->
    <div class="sidebar" id="sidebar">
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
          <ul>
            <li class="menu-title">
              <span>Main</span>
            </li>
            <li class="submenu">
              <a routerLink="/dashboard"><i class="la la-dashboard"></i><span> Dashboard</span></a>
            </li>
            <li class="menu-title">
              <span>Employee</span>
            </li>
            <li class="submenu">
              <a routerLink="/all-employees" *ngIf="userAuth.isAdmin"><i class="la la-user"></i> <span> All Employees</span></a>
              <a routerLink="/departments" *ngIf="userAuth.isAdmin"><i class="la la-group"></i> <span> Departments</span></a>
              <a routerLink="/holidays"><i class="la la-coffee"></i> <span> Holidays</span></a>
              <a routerLink="/vacations"><i class="la la-comments-o"></i> <span> Vacations</span></a>
              <a routerLink="/overtime"><i class="la la-stack-overflow"></i> <span> Overtime</span></a>
            </li>
            <li class="menu-title">
              <span>User</span>
            </li>
            <li class="submenu">
              <a [routerLink]="['/my-profile', userAuth.id, userAuth.slug]"><i class="la la-cogs"></i> <span> Profile</span></a>
              <a routerLink="/change-password"><i class="la la-street-view"></i> <span> Change Password</span></a>
            </li>
            <li class="menu-title">
              <span>Logout</span>
            </li>
            <li class="submenu">
              <a style="cursor: pointer;" (click)="logout()"><i class="la la-cogs"></i> <span> Logout</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  <!-- /Sidebar -->
</div>




<script src="../../../../assets/js/jquery-3.2.1.min.js"></script>
<script src="../../../../assets/js/popper.min.js"></script>
<script src="../../../../assets/js/bootstrap.min.js"></script>
<script src="../../../../assets/js/jquery.slimscroll.min.js"></script>
<script src="../../../../assets/plugins/morris/morris.min.js"></script>
<script src="../../../../assets/plugins/raphael/raphael.min.js"></script>
<script src="../../../../assets/js/chart.js"></script>
<script src="../../../../assets/js/app.js"></script>
