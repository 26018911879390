<body class="account-page">
  <div class="main-wrapper" style="margin-top: 110px;">
    <div class="account-content">
      <div class="container">
        <div class="account-logo">
          <a href="index.html"><img src="../../../assets/img/logo.png" alt="Dreamguy's Technologies"></a>
        </div>
        <div class="account-box">
          <div class="account-wrapper">
            <p class="account-subtitle">Enter your new password below</p>
            <form action="" [formGroup]="userPassword">
              <div class="form-group">
                <label>Password</label>
                <input class="form-control" placeholder="Password..." [(ngModel)]="firstPassword" [class.is-invalid] = "userPassword.get('password').invalid && userPassword.get('password').touched" formControlName="password" required type="password" name="site" required="" required autofocus="" onpaste="return false;" ondrop="return false;" autocomplete="off" #passwordValue>
              </div>
              <div *ngIf="(primPassword.invalid && primPassword.touched) || primPassword.dirty">
                <p *ngIf="primPassword.errors?.required" class="text-danger">Password is required</p>
                <p *ngIf="primPassword.errors?.pattern" class="text-danger">
                  <small *ngIf="firstPassword.length<8">Password should have minimum 8 characters, at least 1 uppercase letter, at least 1 lowercase letter, at least 1 number, at least 1 character</small>
                </p>
              </div>
              <div class="form-group">
                <label>Repeat Password</label>
                <input placeholder="Confirm password..." [(ngModel)]="secondPassword" [class.is-invalid] = "userPassword.get('secondPass').invalid && userPassword.get('secondPass').touched" formControlName="secondPass" required type="password" class="form-control" name="site" required="" required autofocus="" onpaste="return false;" ondrop="return false;" autocomplete="off">
              </div>
              <div *ngIf="(secPassword.invalid && secPassword.touched) || secPassword.dirty">
                <small *ngIf="firstPassword !== secondPassword" class="text-danger">Passwords are not the same</small>
              </div>
              <div class="form-group text-center">
                <button class="btn btn-primary account-btn" name="submit" type="submit" value="Submit" [disabled]="!userPassword.valid || (firstPassword !== secondPassword)" [ngStyle]="{'cursor' : !userPassword.valid || (firstPassword !== secondPassword) ? 'not-allowed' : 'pointer'}" (click)="resetPassword(passwordValue.value)">Reset Password</button>
              </div>
              <div class="account-footer">
                <p>Remember password? <a routerLink="/login">Login</a></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <script src="../../../assets/js/jquery-3.2.1.min.js"></script>
  <script src="../../../assets/js/popper.min.js"></script>
  <script src="../../../assets/js/bootstrap.min.js"></script>
  <script src="../../../assets/js/app.js"></script>
</body>
