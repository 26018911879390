import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth-routing.routing';
import { LoginComponent } from './components/login.component';
import { SharedModule } from '../shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { ForgetPasswordComponent } from './components/forget-password.component';
import { ResetPasswordComponent } from './components/reset-password.component';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  imports:      [
    AuthRoutingModule,
    SharedModule,
    TranslateModule,
    RecaptchaModule
  ],
  declarations: [
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
  ],
  providers: [
    AuthService
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class AuthModule { }
