import { Component, OnInit, ViewEncapsulation, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Upload } from '../models/upload.model';
import { UploadService } from '../services/upload.service';
import {EventEmitterService} from '../../../common/page.events';
import { Auth } from 'src/app/common/auth';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Url } from 'src/app/common/url';


@Component({
    selector: "app-topbar",
    templateUrl: "../views/topbar.component.html",
    styleUrls: ['../styles/topbar.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class TopbarComponent implements OnInit {


  userAuth: Auth = Auth.getDataOfUser();
  login = true;
  openMobileMenu = false;
  openAdminDropDown = false;
  openSideBar = false;


  constructor(private router: Router,
              private EventEmitterService: EventEmitterService,
              private upload: UploadService,
              private translate: TranslateService,
              public sanitizer: DomSanitizer) {

  }

  ngOnInit() {

  }

  logout() {
    this.login = false;
    this.EventEmitterService.searchEvent.next({ logOut: true });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('lang');
    this.router.navigate(['/login']);
  }

  menuClicked() {
    this.openMobileMenu = true;
  }

  menuClickedClose() {
    this.openMobileMenu = false;
  }

  navigateMobile(value) {
    this.router.navigate([value]);
    this.openMobileMenu = false;
  }

  adminClicked() {
    this.openAdminDropDown != this.openAdminDropDown;
    console.error(this.openAdminDropDown);
  }

  getSantizeUrl(url : string) {
    if(url == '' || url == null || url == undefined) {
      return this.sanitizer.bypassSecurityTrustUrl(Url.apiImagePath + 'user.jpg');
    }
    else {
      return this.sanitizer.bypassSecurityTrustUrl(Url.apiImagePath + url);
    }
  }

  sidebarClicked() {
    this.openSideBar = !this.openSideBar;
  }

}

