<body class="account-page">
  <div class="main-wrapper" style="margin-top: 110px;">
    <div class="account-content">
      <div class="container">
        <div class="account-logo">
          <a><img src="../../../assets/img/logo.png" alt="Dreamguy's Technologies"></a>
        </div>
        <div class="account-box">
          <div class="account-wrapper">
            <h3 class="account-title">Forgot Password?</h3>
            <p class="account-subtitle">Enter your email to get a password reset link</p>
            <form action="" [formGroup]="userEmail">
              <div class="form-group">
                <label>Email Address</label>
                <input placeholder="Email..." [class.is-invalid] = "userEmail.get('email').invalid && userEmail.get('email').touched" formControlName="email" class="form-control" type="email" id="email-for-pass" required="" #email required pattern="^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},*[\W]*)+$" [(ngModel)]="emailValue">
              </div>
              <div *ngIf="(primEmail.invalid && primEmail.touched) || primEmail.dirty">
                <small *ngIf="primEmail.errors?.required" class="text-danger">{{ 'email is required' | translate | titlecase }}</small>
                <small *ngIf="primEmail.errors?.pattern" class="text-danger">{{ 'please provide a valid email address' | translate | titlecase }}</small>
              </div>
              <div class="form-group text-center">
                <button class="btn btn-primary account-btn" [ngStyle]="{'display' : !displayForgetPassword ? 'none' : ''}" name="submit" type="submit" value="Submit" [disabled]="!userEmail.valid" (click)="getEmail()">Reset Password</button>
              </div>
              <div class="account-footer">
                <p>Remember your password? <a routerLink="/login">Login</a></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <script src="../../../assets/js/jquery-3.2.1.min.js"></script>
  <script src="../../../assets/js/popper.min.js"></script>
  <script src="../../../assets/js/bootstrap.min.js"></script>
  <script src="../../../assets/js/app.js"></script>
</body>
