import { AdminGuard } from './../common/admin.guard';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgModule } from '@angular/core';
import { CmsComponent } from './cms.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../common/auth.guard';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';


const routes: Routes = [
  {
    'path': '',
    'component': CmsComponent,
    'canActivate': [AuthGuard],
    'children': [
      {
        'path': 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        'path': 'my-profile/:id/:slug',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        'path': 'all-employees',
        loadChildren: () => import('./pages/employee/employee.module').then(m => m.EmployeeModule)
      },
      {
        'path': 'holidays',
        loadChildren: () => import('./pages/holidays/holidays.module').then(m => m.HolidaysModule)
      },
      {
        'path': 'overtime',
        loadChildren: () => import('./pages/overtime/overtime.module').then(m => m.OvertimeModule)
      },
      {
        'path': 'vacations',
        loadChildren: () => import('./pages/leaves-employee/leaves-employee.module').then(m => m.LeavesEmployeeModule)
      },
      {
        'path': 'departments',
        loadChildren: () => import('./pages/departments/departments.module').then(m => m.DepartmentsModule)
      },
      {
        'path': 'change-password',
        loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule)
      },
      {
        'path': '',
        'redirectTo': 'dashboard',
        'pathMatch': 'full'
      },
    ]
  },
  {
    'path': '**',
    'redirectTo': '404',
    'pathMatch': 'full'
  }
];

@NgModule({
    imports: [
      RouterModule.forChild(routes),
    ],
    exports: [RouterModule]
})
export class CmsRoutingModule { }
