export class currentUser {
  id = null;
  name = null;
  email = null;
  password = null;
  role = null;
  attemps = null;
  token = null;
  birthday = null;
  phone = null;
  bank_name = null;
  bank_account_nr = null;
  address = null;
  profile_img_original_name = null;
  profile_img_modified_name = null;
  slug = null;
  department_id = null;
  department_name = null;
  activity = null;
}
