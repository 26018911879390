import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Auth } from 'src/app/common/auth';
import { EventEmitterService } from 'src/app/common/page.events';
import { UploadService } from '../services/upload.service';



@Component({
    selector: 'app-sidebar',
    templateUrl: "../views/sidebar.component.html",
    styleUrls: ['../styles/sidebar.component.css'],
    host: {
      'class': 'app-sidebar',
    },
    encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {


  constructor(private eventEmitterService: EventEmitterService,
              private upload: UploadService) {

  }

  ngOnInit() {


  }


}
